var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('div',{staticStyle:{"overflow-x":"scroll"}},[_c('div',{staticClass:"px40 p20"},[_c('h3',[_vm._v("Overall")]),_c('table',{staticClass:"ui celled table"},[_c('thead',[_c('tr',_vm._l((_vm.overall.headers),function(h){return _c('th',{key:h[0]},[_vm._v(_vm._s(h[0]))])}),0)]),_vm._l((_vm.overall.data),function(v){return _c('tr',{key:v.id},[_c('td',[_vm._v(_vm._s(v.id))]),_c('td',_vm._l((_vm.getUser(v.id)),function(u){return _c('p',{key:u.id,staticClass:"pointer",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(u.username)+" ")])}),0),_c('td',_vm._l((v.files),function(f){return _c('p',{key:f.id,staticClass:"pointer",staticStyle:{"font-size":"12px"},on:{"click":function($event){return _vm.$bus.redirect(f.url, true)}}},[_vm._v(" "+_vm._s(f.name)+" ")])}),0),_vm._l((_vm.overall.headers.slice(3, _vm.overall.headers.length)),function(d){return _c('td',{key:d[0]},[_vm._v(" "+_vm._s(v[d[1]])+" ")])})],2)})],2)]),_vm._l((_vm.grouped),function(v,k){return _c('div',{key:k,staticClass:"px40 p20"},[_c('h3',[_vm._v(" Juror: "),_c('span',[_vm._v(_vm._s(k))])]),_c('table',{staticClass:"ui celled table"},[_vm._m(0,true),_vm._l((v),function(item){return _c('tr',{key:item},[_c('td',[_vm._v(_vm._s(item.submissionId)+" Hello")]),_c('td',_vm._l((_vm.getUser(item.submissionId)),function(u){return _c('p',{key:u.id,staticClass:"pointer",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(u.username)+" ")])}),0),_c('td',_vm._l((item.event_submission
              ? item.event_submission.files
              : []),function(f){return _c('p',{key:f.id,staticClass:"pointer",staticStyle:{"font-size":"12px"},on:{"click":function($event){return _vm.window.open(
                _vm.link,
                '_blank',
                'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no'
              )}}},[_vm._v(" "+_vm._s(f.name)+" ")])}),0),_c('td',[_vm._v(_vm._s(item.ranking))]),_c('td',[_vm._v(_vm._s(item.marks))]),_c('td',[_vm._v(_vm._s(item.grade))])])})],2)])})],2):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Entry Id")]),_c('th',[_vm._v("User")]),_c('th',[_vm._v("Files")]),_c('th',[_vm._v("Ranking")]),_c('th',[_vm._v("Marks")]),_c('th',[_vm._v("Grade")])])])
}]

export { render, staticRenderFns }